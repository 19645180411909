<template>
  <div>
    <v-row align="center" justify="center" class="">
      <h1 class="text-center mt-6" style="color: #d5ab61; font-size: 30px">
        ตะกร้าใส่ลอตเตอรี่
      </h1>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="96vw">
        <v-card>
          <v-card-title class="white--text" style="background-color: #2560a1">
            <span style="">ข้อตกลงและเงื่อนไข</span>
            <span style="">ในการซื้อลอตเตอรี่ออนไลน์</span>
            <span style="">ผ่านบางกอกลอตเตอรี่.com</span>
            <br />
            <!-- <span style=""> โดย บริษัท มังกร ดิจิตอล จำกัด </span>
            <span style="">(BD00029D) ตัวแทนจำหน่ายยี่ปั๊วรายใหญ่</span> -->
          </v-card-title>

          <v-card-text>
            <span
              >บางกอกลอตเตอรี่.com คือแพลตฟอร์มจำหน่ายลอตเตอรี่ ในราคาใบละ 80
              บาท โดยผู้จำหน่าย (ผู้ค้าสลากรายย่อย)
              จะมีค่าบริการและค่าดำเนินการตามอัตราเรียกเก็บ
              โดยผู้สั่งซื้อจะได้รับการบริการดังต่อไปนี้</span
            ><br />
            <span
              ><li>บริการ ระบบจัดหาลอตเตอรี่ด้วยระบบโปรแกรมอัจฉริยะ</li>
              <li>บริการ แจ้งเตือนรายการสั่งซื้อไปยัง sms ของผู้สั่งซื้อ</li>
              <li>บริการ รับฝากลอตเตอรี่ (พร้อมประกันสูญหาย)</li>
              <li>บริการ รับฝากตรวจผลลอตเตอรี่</li>
              <li>บริการ แจ้งผลถูกรางวัลไปยัง sms ของผู้สั่งซื้อ</li>
              <li>บริการ รับขึ้นเงินรางวัล ภายใน 24 - 48 ชั่วโมง</li></span
            ><br />
            <span>เงื่อนไขการขอรับต้นฉบับลอตเตอรี่</span><br />
            <span>
              - หากลอตเตอรี่ของลูกค้าถูกรางวัล
              สามารถแจ้งความประสงค์เพื่อรับลอตเตอรี่ใบจริงเพื่อนำไปขึ้นเงินด้วยตนเองได้
              หรือสามารถให้บางกอกลอตเตอรี่.comนำลอตเตอรี่ไปขึ้นเงินแทนลูกค้าเพื่อความสะดวก
              โดยจะมีค่าธรรมเนียมการขึ้นเงินรางวัล 2 เปอร์เซ็นต์จากเงินรางวัล
              และทางบางกอกลอตเตอรี่.comจะดำเนินการโอนเงินรางวัลให้ลูกค้า ภายใน 24 - 48
              ชั่วโมง หลังจากได้รับ SMS ยืนยันการถูกรางวัลจากทางบางกอกลอตเตอรี่.com </span
            ><br />
            <span
              >- หากลอตเตอรี่ของลูกค้าไม่ถูกรางวัลในงวดนั้น
              ข้าพเจ้าลูกค้าผู้ซื้อขอยกเลิกการฝากลอตเตอรรี่ไว้กับบางกอกลอตเตอรี่.comและให้กรรมสิทธิ์ในลอตเตอรี่ใบที่ไม่ถูกรางวัลนั้น
              ตกเป็นกรรมสิทธิ์ของบางกอกลอตเตอรี่.comเพื่อนำไปใช้ประโยชน์ เช่น บริจาค
              รีไซเคิล ทำลาย
              หรือดำเนินการใดตามประสงค์ของบางกอกลอตเตอรี่.comได้ทันทีเมื่อสิ้นสุดการออกรางวัลประจำงวด
              โดยลูกค้าผู้ซื้อไม่ติดใจเรียกร้องเอาลอตเตอรี่คืนหรือดำเนินการใดกับลอตเตอรี่ใบดังกล่าวอีกทั้งสิ้น </span
            ><br />
            <span
              >-
              สำหรับลอตเตอรี่ที่ลูกค้าซื้อกับผู้ค้าสลากรายย่อยในระบบแพลตฟอร์มบางกอกลอตเตอรี่.com
              หลังจากผลรางวัลออกในแต่ละงวด
              ทางระบบของบางกอกลอตเตอรี่.comจะตรวจสอบว่าลอตเตอรี่ในระบบของบริษัทบางกอกลอตเตอรี่.comถูกรางวัลหรือไม่ทันที
              หากไม่ถูกรางวัลทางบริษัทบางกอกลอตเตอรี่.comจะคัดแยกออกและให้สลากดังกล่าวตกเป็นกรรมสิทธิของบริษัทบางกอกลอตเตอรี่.comแต่เพียงผู้เดียวทันที
              เนื่องจาก
              บริษัทบางกอกลอตเตอรี่.comจะเก็บรักษาลอตเตอรี่ที่ไม่ถูกรางวัลไว้เป็นระยะเวลา 2
              ปี
              เพื่อให้หน่วยงานที่เกี่ยวข้องสามารถตรวจสอบได้ว่าบริษัทบางกอกลอตเตอรี่.comมีใบจริงที่ตรวจสอบได้แน่นอน </span
            ><br />
            <span
              >- ส่วนกรณีลูกค้าถูกรางวัล แล้วต้องการลอตเตอรี่ใบจริง
              ตรงนี้สามารถแจ้งรับลอตเตอรี่ใบจริงได้เหมือนเดิมตามปกติ</span
            ><br />
            <span
              >หลังจากทำการจองรายการสั่งซื้อแล้ว
              ทางบริษัทจะถือว่าผู้สั่งซื้อได้รับทราบและทำความเข้าใจเกี่ยวกับรายละเอียดของค่าบริการและค่าดำเนินการเป็นอันถี่ถ้วนแล้ว
              ก่อนที่จะทำรายการสั่งซื้อในรายการสั่งซื้อนั้น</span
            ><br />
            <span>
              ข้อมูลการยืนยันนี้จะถูกนำไปบันทึกเป็นหลักฐาน
              หากในกรณีที่ผู้สั่งซื้อแจ้งความประสงค์ไม่ยอมรับกฎข้อกำหนดในการบริการของทางบริษัท</span
            ><br />
            <span>ขอบพระคุณที่เลือกใช้บริการของเรา ขอบคุณครับ / ค่ะ</span><br />
            <!-- <span
              >13. เลขสลากฯ
              ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้
              กรุณาติดต่อรับเงินโอนคืนค่าลอตเตอรี่เต็มจำนวนเท่านั้น
              ยอมรับข้อตกลงและเงื่อนไข
              ข้อตกลงและเงื่อนไขการสั่งซื้อลอตเตอรี่บางกอกลอตเตอรี่.com ลอตเตอรี่ออนไลน์
              โดยบริษัท มังกร ดิจิตอล จำกัด
              เมื่อลูกค้าได้เลือกเลขสลากใส่ตะกร้าสินค้า
              และทำตามขั้นตอนบนเวบไซต์แล้ว ในการกดสั่งซื้อและกดยืนยัน ทางบริษัทฯ
              จะถือว่าลูกค้าได้ตกลงและยอมรับเงื่อนไขของ บจก. มังกร ดิจิตอล และ
              บจก. บางกอกลอตเตอรี่.com ลอตเตอรี่ออนไลน์
              ที่แจ้งเตือนให้อ่านและยอมรับก่อนการกด "ยืนยัน" เรียบร้อยแล้ว •
              โอนเงินและอัพโหลดสลิปโอนเงินบนเวบไซต์ภายใน 1 ชม. เท่านั้น
              เพื่อป้องกันออเดอร์หมดเวลา •
              ลูกค้าต้องตรวจสอบสถานะออเดอร์ของตนเองหลังการสั่งซื้อทุกครั้งที่เมนู
              "ประวัติการสั่งซื้อ" • หากอัพโหลดสลิปด้วยตนเองไม่ได้
              ส่งให้เราทางไลน์ช่วยอัพโหลดแทนได้ (ทั้งนี้
              แอดมินเป็นเพียงผู้ช่วยเหลืออัพโหลดสลิปแทนเท่านั้น
              ดังนั้นกรุณาส่งสลิปแจ้งโอนก่อนหมดเวลามากกว่า 30 นาที) •
              หากส่งสลิปโอนแล้วหรืออัพโหลดสลิปโอนแล้ว แต่ยังไม่ได้รับ SMS
              ยืนยันออเดอร์สมบูรณ์ ใน 30 นาที
              ลูกค้าต้องแจ้งตัวแทนทันทีทางไลน์เท่านั้น •
              หากออเดอร์หมดเวลาทุกกรณี
              ลูกค้าสามารถสั่งซื้อใหม่อีกครั้งได้ด้วยการแนบสลิปเดิมที่โอนแล้ว
              หรือกรณีลอตเตอรี่หมดแล้วกรุณาติดต่อรับเงินโอนคืนเต็มจำนวน •
              เลขสลากฯ ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้</span
            > -->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> ถอยกลับ </v-btn>
            <v-btn
              color="primary"
              @click="
                checkPrivacy = true;
                dialog = false;
              "
            >
              ฉันยอมรับ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row align="center" justify="center" class="mb-16">
      <v-card class="ma-4 pa-2 text-center sec-cart mb-10" color="#2560a1">
        <div class="lotto-scroll">
          <div class="lotto-group">
            <!-- <div class="info1">เลขชุด<b>2</b>ใบ<b>12</b>ล้าน</div> -->
            <div class="pic">
              <img
                v-for="(cart, index) in carts"
                :key="index"
                :src="cart.lottery.urlImage"
                alt="235300"
                class="sizeimg pr-2"
              />
            </div>

            <!-- <div class="info2">
              <v-img
                max-width="35px"
                class="mx-auto mb-2"
                src="@/assets/eriam-logo.png"
              ></v-img>
            </div> -->
          </div>
        </div>
        <v-row
          class="text-center mt-4"
          align="center"
          justify="center"
          v-if="carts.length !== 0"
        >
          <h2 style="color: white">ลอตเตอรี่ที่เลือก</h2>
        </v-row>
        <v-form ref="form1" :lazy-validation="lazy">
          <v-row align="center" justify="center" v-if="carts.length !== 0">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="3">หมายเลข</v-col>
                <v-col cols="3">จำนวน</v-col>
                <v-col cols="3">ราคา</v-col>
                <v-col cols="3">ค่าบริการ</v-col>
                <!-- <v-col cols="2">ตัวเลือก</v-col> -->
              </v-row>
            </v-col>
            <v-col cols="12"
              ><v-row v-for="(cart, index) in carts" :key="index" no-gutters>
                <v-col cols="3"> {{ cart.lottery.lotteryNo }} </v-col>
                <v-col cols="3">{{ cart.amount }} ใบ</v-col>
                <!-- <v-col cols="3"
                  >{{ cart.lottery.price * cart.amount }} บาท</v-col
                > -->
                <v-col cols="3">80 บาท</v-col>
                <v-col cols="3"
                  >{{ feePrice }} บาท
                  <v-icon @click="deleteCart(cart.id)"
                    >mdi-delete</v-icon
                  ></v-col
                >
              </v-row>

              <v-divider style="margin: 20px"></v-divider>

              <v-row justify="end">
                <v-col cols="6" class="text-right">
                  <span
                    style="color: white; font-weight: 900; font-size: 0.9rem"
                  >
                    รวมจำนวน:
                  </span></v-col
                >
                <v-col cols="3" md="2" class="text-left"
                  >{{ totalAmount }} ใบ</v-col
                >
              </v-row>
              <v-row justify="end">
                <v-col cols="6" class="text-right">
                  <span
                    style="color: white; font-weight: 900; font-size: 0.9rem"
                  >
                    รวมราคา:
                  </span></v-col
                >
                <v-col cols="3" md="2" class="text-left"
                  >{{ totalPrice }} บาท</v-col
                >
              </v-row>
              <v-row justify="end">
                <v-col cols="6" class="text-right">
                  <span
                    style="color: white; font-weight: 900; font-size: 0.9rem"
                  >
                    บริการการรับประกัน:
                  </span></v-col
                >
                <v-col cols="3" md="2" class="text-left"
                  >{{ waranty }} บาท</v-col
                >
              </v-row>
              <v-row justify="end">
                <v-col cols="6" class="text-right">
                  <span
                    style="
                      color: white;
                      font-weight: 900;
                      font-size: 1.2rem;
                      text-decoration-line: underline;
                    "
                  >
                    จำนวนเงินทั้งสิ้น:
                  </span></v-col
                >
                <v-col cols="3" md="2" class="text-left">
                  <span
                    style="color: white; font-weight: 900; font-size: 1.2rem"
                    >{{ totalPrice + waranty }} บาท</span
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="6">
              <v-select
                v-model="delivery"
                :items="items"
                :item-text="items.value"
                :item-value="items.text"
                placeholder="เลือกการจัดส่ง*"
                solo
                :rules="Rules.empty"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="delivery == '1'" dense justify="center">
            <v-col cols="10" md="5" sm="5">
              <span>บริการการรับประกัน</span>
              <v-select
                v-model="waranty"
                :items="itemswaranty"
                item-text="price"
                item-value="value"
                placeholder="บริการการรับประกัน*"
                solo
              ></v-select>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              <span>ค่าบริการการรับประกัน</span>
              <v-select
                v-model="waranty"
                :items="itemswaranty"
                item-text="text"
                item-value="value"
                placeholder="ค่าบริการ*"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12" md="10" sm="10">
              <v-card class="pa-4" outlined style="background-color: grey">
                <span style="color: #ffbe00; font-weight: 800" class="blink"
                  >หมายเหตุ ก่อนการจัดส่ง ทางบริษัทฯ จะกรอกชื่อ-นามสกุล
                  และเบอร์โทรลูกค้า
                </span>
                <br />
                <span style="color: #ffbe00; font-weight: 800" class="blink"
                  >ด้านหลังสลากฯ ทุกใบ ตามที่สมัครและกดสั่งซื้อในระบบเท่านั้น
                </span>
              </v-card>
            </v-col>
            <v-col cols="10" class="mt-0"
              >ที่อยู่*
              <v-textarea
                v-model="address"
                rows="3"
                dense
                solo
                label="ที่อยู่"
                :rules="Rules.empty"
              ></v-textarea>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              ตำบล/แขวง
              <v-text-field
                v-model="district"
                solo
                background-color="white"
                label="ตำบล/แขวง"
                :rules="Rules.empty"
              ></v-text-field>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              อำเภอ/เขต*
              <v-text-field
                v-model="subdistrict"
                solo
                background-color="white"
                label="อำเภอ/เขต"
                :rules="Rules.empty"
              ></v-text-field>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              จังหวัด*
              <v-text-field
                v-model="province"
                solo
                background-color="white"
                label="จังหวัด"
                :rules="Rules.empty"
              ></v-text-field>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              รหัสไปรษณีย์*
              <v-text-field
                v-model="postcode"
                solo
                background-color="white"
                label="รหัสไปรษณีย์"
                :rules="Rules.empty"
                v-mask="'#####'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row
          align="center"
          justify="center"
          v-if="carts.length == 0 || carts.length == []"
          class="ma-4"
        >
          <h2 style="color: white">ขออภัย ท่านยังไม่ได้เลือกลอตเตอรี่</h2>
        </v-row>
        <div v-if="carts.length !== 0">
          <v-row class="mt-2" justify="center"
            ><v-col cols="9">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                class="px-10"
                @click="home()"
                >เลือกลอตเตอรี่เพิ่ม</v-btn
              ></v-col
            ></v-row
          >
          <v-col cols="12" class="mt-4">
            <v-row justify="center">
              <v-checkbox
                disabled
                v-model="checkPrivacy"
                label="ฉันยอมรับ "
                style="color: white"
                dark
              >
              </v-checkbox>
              <span
                class="my-5"
                style="text-decoration: underline; font-size: 16px"
                @click="dialogPrivacy()"
              >
                ข้อกำหนดและเงื่อนไขบริการ</span
              >
            </v-row>
          </v-col>

          <v-row class="mt-0" justify="center"
            ><v-col cols="9">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                class="px-10"
                @click="payment()"
              >
                สั่งซื้อและชำระเงิน</v-btn
              ></v-col
            ></v-row
          >
          <v-row class="my-2" justify="center">
            <span>กรณียังไม่ชำระเงิน คนอื่นสามารถซื้อตัดหน้าได้!</span>
          </v-row>
        </div>
        <div v-else>
          <v-row class="mt-2" justify="center"
            ><v-col cols="9">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                class="px-10 mb-4"
                @click="home()"
                >เลือกลอตเตอรี่</v-btn
              ></v-col
            ></v-row
          >
        </div>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import ModaltermOfServices from "@/components/User/Modal/ModalTermofServices";
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      dialog: false,
      checkPrivacy: false,
      lazy: false,
      carts: [],
      userData: "",
      totalPrice: 0,
      totalAmount: 0,
      sumTotal: 0,
      address: "",
      subdistrict: "",
      district: "",
      province: "",
      postcode: "",
      delivery: "0",
      feePrice: 0,
      waranty: 0,
      items: [
        { text: "ฝากลอตเตอรี่ไว้กับเรา", value: "0" },
        { text: "จัดส่งไปรษณีย์ EMS", value: "1" },
      ],
      // ไม่มีค่าบริการ = วงเงินสูญหาย 2,000 บาท
      // 50 บาท = วงเงินสูญหาย 5,000 บาท
      // 100 บาท = วงเงินสูญหาย 10,000 บาท
      // 200 บาท = วงเงินสูญหาย 20,000 บาท
      // 300 บาท = วงเงินสูญหาย 30,000 บาท
      // 400 บาท = วงเงินสูญหาย 40,000 บาท
      // 500 บาท = วงเงินสูญหาย 50,000 บาท
      itemswaranty: [
        { text: "วงเงินสูญหาย 2,000 บาท", value: 0, price: "ไม่มีค่าบริการ" },
        { text: "วงเงินสูญหาย 5,000 บาท", value: 50, price: "50 บาท" },
        { text: "วงเงินสูญหาย 10,000 บาท", value: 100, price: "100 บาท" },
        { text: "วงเงินสูญหาย 20,000 บาท", value: 200, price: "200 บาท" },
        { text: "วงเงินสูญหาย 30,000 บาท", value: 300, price: "300 บาท" },
        { text: "วงเงินสูญหาย 40,000 บาท", value: 400, price: "400 บาท" },
        { text: "วงเงินสูญหาย 50,000 บาท", value: 500, price: "500 บาท" },
      ],
      Rules: {
        cardNo: [(v) => !!v || "กรุณาระบุหมายเลขบัตร"],
        cardName: [(v) => !!v || "กรุณาระบุชื่อผู้ถือเลขบัตร"],
        expireMonth: [(v) => !!v || "กรุณาระบุเดือนที่หมดอายุ"],
        expireYear: [(v) => !!v || "กรุณาระบุปีที่หมดอายุ"],
        ccv: [(v) => !!v || "กรุณาระบุ ccv"],
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  async created() {
    var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    const auth = {
      headers: { Authorization: `Bearer ${user.token}` },
    };
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/users/` + user.user.id,
      auth
    );
    console.log("response", response);
    // var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    // console.log("firstName", user.firstName);
    // console.log("lastName", user.lastName);
    if (
      response.data.data.firstName == "" ||
      response.data.data.lastName == "" ||
      response.data.data.firstName == null ||
      response.data.data.lastName == null
    ) {
      await this.$swal.fire({
        icon: "error",
        text: "กรุณาระบุชื่อ- นามสกุล ก่อนสั่งซื้อ",
        showConfirmButton: true,
        // timer: 1500,
      });
      await this.$router.push({ path: "/member" }).catch(() => {});
    }
    this.carts = [];
    this.getCart();
  },
  components: {
    ModaltermOfServices,
  },
  methods: {
    async checkPrivacyNa() {
      if (this.checkPrivacy == false) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณายอมรับข้อกำหนดและเงื่อนไขบริการ",
        });
      }
    },
    dialogPrivacy() {
      this.dialog = true;
    },
    home() {
      this.$router.push("/lotterythai");
    },
    async payment() {
      this.checkPrivacyNa();
      if (this.checkPrivacy == true) {
        if (this.$refs.form1.validate(true)) {
          const paymcartsentData = {
            isDelivery: this.delivery,
            address: {
              address: this.address,
              subDistrict: this.subdistrict,
              district: this.district,
              province: this.province,
              postcode: this.postcode,
            },
            waranty: this.waranty,
          };
          localStorage.setItem("paymcartsentData", encode(paymcartsentData));
          this.$router.push("payment");
        } else {
          await this.$swal.fire({
            showConfirmButton: true,
            // timer: 2000,
            // timerProgressBar: true,
            icon: "warning",

            html: "<b>กรุณาระบุข้อมูลจัดส่ง</b> <br/> <span style='color:red;font-size:14px'>หมายเหตุ ก่อนการจัดส่ง ทางบริษัทฯ จะกรอกชื่อ-นามสกุล และเบอร์โทรลูกค้า ด้านหลังสลากฯ ทุกใบ ตามที่สมัครและกดสั่งซื้อในระบบเท่านั้น</span>",
          });
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          icon: "warning",
          text: "กรุณายอมรับข้อกำหนดและเงื่อนไขบริการ",
        });
        await this.dialogPrivacy();
      }
    },
    // RegisterService() {
    //   this.$router
    //     .push({ path: "/step1?status=service&&type=create" })
    //     .catch(() => {});
    // },
    // RegisterProvider() {
    //   this.$router
    //     .push({ path: "/step1?status=provider&&type=create" })
    //     .catch(() => {});
    // },
    // OpenModal() {
    //   this.$EventBus.$emit("ModalPopUp");
    // },
    async deleteCart(val) {
      console.log(val);
      this.$swal({
        text: "คุณต้องการลบลอตเตอรี่ที่เลือกใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ลบลอตเตอรี่ออกจากตะกร้า",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/cart/delete/` + val,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบลอตเตอรี่สำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          this.totalPrice = 0;
          this.totalAmount = 0;
          await this.getCart();
        }
      });
    },
    async getCart() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // console.log("user", user.token);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
        auth
      );
      console.log("response", response.data.data);
      this.carts = response.data.data;
      this.$store.dispatch("ActionsSetCars", response.data.data);

      const responseConfig = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/OPERATION_PRICE`,
        auth
      );
      console.log("responseConfig", responseConfig.data.data);
      this.feePrice = responseConfig.data.data.value1 * 1;

      this.carts.forEach((cart) => {
        cart.lottery.urlImage = cart.lottery.urlImage.replace(
          "20210425",
          "20210424"
        );
        this.totalPrice =
          this.totalPrice +
          (cart.lottery.price + responseConfig.data.data.value1 * 1) *
            cart.amount;
        this.totalAmount = this.totalAmount + cart.amount;
      });
      console.log("carts", this.carts);
      console.log(this.totalPrice);
      console.log(this.totalAmount);

      // this.lotteries = response.data.data;
      // this.lotteries.forEach((lot) => {
      //   lot.urlImage = lot.urlImage.replace("20210425", "20210424");
      //   console.log(lot.urlImage);
      // });
    },
    // async addToCart(lotto) {
    //   if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
    //     this.StatusLogin = true;
    //     var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    //     // this.Username = `${user.user.firstName} ${user.user.lastName}`;
    //     console.log(user);

    //     const data = {
    //       lotteryId: lotto.id,
    //       amount: 1,
    //       userId: user.user.id,
    //     };

    //     console.log(data);

    //     const auth = {
    //       headers: { Authorization: `Bearer ${user.user.token}` },
    //     };
    //     const response = await this.axios.post(
    //       `${process.env.VUE_APP_API}/cart/create`,
    //       // "http://localhost:8081/cart/create",
    //       data
    //       // auth
    //     );
    //     console.log("response", response.data.data);
    //     if (response.data.response_status === "SUCCESS") {
    //       this.$router.push({ path: "/cart" }).catch(() => {});
    //     }
    //   } else {
    //     this.StatusLogin = false;
    //     this.$router.push({ path: "/member" }).catch(() => {});
    //   }
    // },
  },
};
</script>
<style scoped>
.sec-cart {
  border-radius: 8px;
  padding: 8px;
  background: #00739c;
  margin: 0 -12px 20px;
  color: #fff;
}
.info2 {
  width: 15%;
  height: 30px;
  margin-top: -39px;
  margin-left: 170px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  /* min-width: 5%;
  max-height: 10%; */
  background: #f3e0bf73;
  backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}
/* .lotto-group {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 47%;
  z-index: 20;
  background-color: #e8dfc4;
}

.lotto-scroll {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
}
.lotto-scroll .lotto-group {
  display: flex;
  width: auto;
  margin: 0 20px 0 0;
  padding: 0 0 10px;
  background: 0 0;
} */
/* .lotto-group .info1 {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 60px;
  max-height: 120px;
  background: rgba(255, 94, 239, 0.85);
  backdrop-filter: blur(4px);
  text-align: center;
  color: #000;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
  font-size: 10px;
  padding: 10px 0;
} */
/* .lotto-scroll .lotto-group .pic {
  display: flex;
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  overflow: unset;
  width: auto;
  align-items: flex-start;
} */
.sizeimg {
  width: 40vw;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
